<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('teachersList') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button size="default" @click="addItem()">
                            {{ $t('add') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod"> </el-table-column>
                <el-table-column sortable :label="$t('name')" prop="name">
                    <template #default="scope">
                        {{ getTitleLabel(scope.row.title) + ' ' + scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('phone')" prop="phone"> </el-table-column>
                <el-table-column sortable :label="$t('email')" prop="email"> </el-table-column>
                <el-table-column sortable :label="$t('logSettings')" width="225">
                    <template #default="scope">
                        <el-button size="small" type="info" @click="viewSettings(scope.$index, scope.row)">
                            {{ $t('view') }}
                        </el-button>
                        <el-button size="small" type="primary" @click="copySettings(scope.$index, scope.row)">
                            {{ $t('copy') }}
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column align="right" width="225">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" @click="editItem(scope.$index, scope.row)">
                            {{ $t('edit') }}
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)">
                            {{ $t('delete') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog :title="$t('addTeacher')" v-model="addDialog" width="35%">

            <el-form
                :rules="rules"
                :model="addTeacher"
                label-width="140px"
                label-position="left"
                :ref="addTeacherForm"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="addTeacher.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        :placeholder="$t('name')"
                        v-model="addTeacher.name"/>
                </el-form-item>

                <el-form-item prop="phone" :label="$t('phone')">
                    <el-input
                        :placeholder="$t('phone')"
                        v-model="addTeacher.phone"/>
                </el-form-item>

                <el-form-item prop="email" :label="$t('email')">
                    <el-input
                        :placeholder="$t('email')"
                        v-model="addTeacher.email"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(addTeacherForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(addTeacherForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

        <el-dialog :title="$t('editTeacher')" v-model="editDialog" width="35%">

            <el-form
                :rules="rules"
                :model="editTeacher"
                label-width="140px"
                label-position="left"
                :ref="editTeacherForm"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="editTeacher.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        :placeholder="$t('name')"
                        v-model="editTeacher.name"/>
                </el-form-item>

                <el-form-item prop="phone" :label="$t('phone')">
                    <el-input
                        :placeholder="$t('phone')"
                        v-model="editTeacher.phone"/>
                </el-form-item>

                <el-form-item prop="email" :label="$t('email')">
                    <el-input
                        :placeholder="$t('email')"
                        v-model="editTeacher.email"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(editTeacherForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(editTeacherForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { Titles } from '@/utils/enums'

import { Roles } from '@/utils/enums'
import { genPassword } from '@/utils/functions'

import { v4 as uuidv4 } from 'uuid'
import sanitizer from 'string-sanitizer'
import useClipboard from 'vue-clipboard3'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Teachers',
    mixins: [accessManager],
    data() {
      return {
        titles: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        editTeacher: {
            title: '',
            name: '',
            phone: '',
            email: ''
        },
        addTeacher: {
            title: '',
            name: '',
            phone: '',
            email: ''
        },
        rules: {
            title: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            name: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            phone: [
                {
                    required: false
                }
            ],
            email: [
                {
                    required: false
                }
            ]
        },
        editTeacherForm: 'editTeacherForm',
        addTeacherForm: 'addTeacherForm',
        currentEditItem: null,
        editDialog: false,
        isLoading: false,
        addDialog: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchTeachers()

      var self = this

      this.$_.forIn(Titles, function(value, key) {

          self.titles.push(value)

      })

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()))

      }
    },
    methods: {
      ...mapActions({
         fetchTeachersDB: 'teachers/fetchTeachersDB',
         fetchUserDB: 'user/fetchUserDB'
      }),
      setPage (val) {

         this.page = val

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      getTitleLabel(id){

          let title = this.titles.find(t => t.id == id)

          return title ? title.label : ''

      },
      async genUid(uidSrc, addSalt){

            var uid = sanitizer.sanitize(uidSrc.toLowerCase()).substring(0, 10)

            if (addSalt) uid += this.$_.random(1, 99, false)

            var task = await this.fetchUserDB({
                action: 'checkUserExists',
                params: {
                    uid: uid
                }
            })

            if (task.success && !task.data) {

                return uid

            }else{

                return await this.genUid(uidSrc, true)

            }

      },
      async fetchTeachers(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp = await this.fetchTeachersDB({
            action: 'getTeachers',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data) this.tableData = resp.data

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.isLoading = false

      },
      addItem(){

           if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
           }

           this.addDialog = true

      },
      async editItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.currentEditItem = item

            this.editTeacher.name = item.name
            this.editTeacher.phone = item.phone
            this.editTeacher.email = item.email
            this.editTeacher.title = parseInt(item.title)

            this.editDialog = true

      },
      async viewSettings(index, item){

            let task = await this.fetchUserDB({
                action: 'getUser',
                params: {
                    id: item.user
                }
            })

            if (task.success && task.data) {

                this.$alert(
                    '<b>' + this.$t('id') + '</b> : ' + task.data.uid + '<br>' +
                    '<b>' + this.$t('password') + '</b> : ' + task.data.password,
                    this.$t('logSettings'), {
                        center: true,
                        showClose: true,
                        closeOnClickModal: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        closeOnPressEscape: true,
                        dangerouslyUseHTMLString: true
                })

            } else {

                this.$message.error(this.$t('anErrorOccured'))

            }

      },
      async copySettings(index, item){

            let task = await this.fetchUserDB({
                action: 'getUser',
                params: {
                    id: item.user
                }
            })

            if (task.success && task.data) {

                const { toClipboard } = useClipboard()

                try {

                    await toClipboard(this.$t('id') + ': ' + task.data.uid + ' ' + this.$t('password') + ': ' + task.data.password)

                    this.$message.success(this.$t('success'))

                } catch (e) {

                    console.error(e)

                    this.$message.error(this.$t('anErrorOccured'))

                }

            }

      },
      deleteItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.$confirm(this.$t('deleteTeacherQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchTeachersDB({
                    action: 'deleteTeacher',
                    params: {
                        id: item.id,
                        user: item.user
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchTeachers()

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

      },
      submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === this.addTeacherForm){

                        let uid = await this.genUid(this.addTeacher.name, false)

                        let task = await this.fetchTeachersDB({
                            action: 'addTeacher',
                            params: {
                                uid: uid,
                                hash_id: uuidv4(),
                                role: Roles.TEACHER,
                                password: genPassword(),
                                title: this.addTeacher.title,
                                name: this.addTeacher.name,
                                phone: this.addTeacher.phone,
                                email: this.addTeacher.email,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        if (this.currentEditItem.title !== this.editTeacher.title) {
                            newData['title'] = this.editTeacher.title
                        }

                        if (this.currentEditItem.name !== this.editTeacher.name) {
                            newData['name'] = this.editTeacher.name
                        }

                        if (this.currentEditItem.phone !== this.editTeacher.phone) {
                            newData['phone'] = this.editTeacher.phone
                        }

                        if (this.currentEditItem.email !== this.editTeacher.email) {
                            newData['email'] = this.editTeacher.email
                        }

                        if (!this.$_.isEmpty(newData)){
                            let task = await this.fetchTeachersDB({
                                action: 'editTeacher',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))
                        }

                    }

                    this.cancelForm(ref)
                    this.fetchTeachers()

                } else {
                    return false
                }
            })

      },
      cancelForm(ref){

            this.$refs[ref].resetFields()

            if (ref === this.addTeacherForm) this.addDialog = false
            else this.editDialog = false

      }
    },
}
</script>